<template>
  <v-row>
    <v-col cols="12" class="d-flex align-center">
      <h2>{{ $t('bank.head') }}</h2>
      <v-divider class="mx-3" vertical />
      <v-breadcrumbs :items="breadcrumbsItems" divider=">>" />
    </v-col>
    <v-divider class="mx-3" vertical />
    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t('bank.head_list2') }} </v-card-title>
        <v-form ref="form" v-model="formValid">
          <v-divider class="mx-3" />
          <v-card-actions>
            <v-row>
              <v-col cols="4" xs="12">
                <v-select v-model="datas.bank" :rules="[v => !!v || '']" :label="$t('bank.header1')" :items="bankItems"
                  item-text="label" outlined dense readonly disabled />
              </v-col>
              <v-col cols="4" xs="12">
                <v-text-field v-model="datas.bank_name" :rules="[v => !!v || '']" :label="$t('bank.header2')" outlined
                  dense readonly disabled />
              </v-col>
              <v-col cols="4" xs="12">
                <v-text-field v-model="datas.bank_account" :rules="[v => !!v || '']" :label="$t('bank.header9')" outlined
                  dense readonly disabled />
              </v-col>
            </v-row>
          </v-card-actions>
          <v-card-actions>
            <v-row>
              <v-col cols="4" xs="12">
                <v-text-field v-model="datas.username" label="Username" outlined dense />
              </v-col>
              <v-col cols="4" xs="12">
                <v-text-field v-model="datas.password" label="Password" outlined dense type="password" />
              </v-col>
              <v-col cols="4" xs="12" class="d-flex">
                <v-text-field :label="$t('bank.header10')" v-model="datas.promptpay" outlined dense style="width: 400px"
                  class="pr-1" />
                <!-- <v-select label="สถานะ" :items="statusItems" outlined dense class="pl-5" /> -->
              </v-col>
            </v-row>
          </v-card-actions>
          <v-card-actions>
            <v-row>
              <v-col cols="4" xs="12">
                <v-text-field :label="$t('bank.header11')" outlined dense :hint="$t('bank.header11_hint')"
                  :rules="[v => !!v || '']" v-model="datas.time_from" />
              </v-col>
              <v-col cols="4" xs="12">
                <v-text-field :label="$t('bank.header12')" outlined dense :rules="[v => !!v || '']"
                  :hint="$t('bank.header12_hint')" v-model="datas.time_to" />
              </v-col>
            </v-row>
          </v-card-actions>
          <v-card-actions>
            <v-row>
              <v-col cols="4" xs="12">
                <v-select v-model="datas.use" :label="$t('bank.header14')" :items="statusItems" outlined dense />
              </v-col>
              <v-col cols="4" xs="12">
                <v-select v-model="datas.use_withdraw" :label="$t('bank.header15')" :items="statusItems" outlined dense />
              </v-col>
              <v-col cols="4" xs="12">
                <v-text-field :label="$t('bank.header5')" v-model="datas.limit_withdraw" v-if="datas.use_withdraw"
                  outlined type="number" :rules="[v => !!v || '']" dense />
              </v-col>
              <v-col cols="8" xs="12">
                <v-textarea :label="$t('bank.header13')" v-model="datas.detail" outlined dense />
              </v-col>
            </v-row>
          </v-card-actions>
          <v-divider class="mx-3" />
          <v-card-actions>
            <v-spacer />
            <v-btn color="warning" @click="$router.push({ name: 'bank' })">
              <v-icon>mdi-arrow-left</v-icon>
              <span class="px-3">{{ $t('back') }}</span>
            </v-btn>
            <v-btn color="success" :loading="loading" @click="save" class="px-3">
              <v-icon>mdi-content-save</v-icon>
              <span class="px-3">{{ $t('save') }}</span>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import store from '@/store'
export default {
  data() {
    return {
      formValid: true,
      loading: false,
      formRule: {
        username: [
          value => !!value || 'username is required.',
          value => (!!value && value.length > 5) || 'need more than 5 characters',
        ],
        password: [
          value => !!value || 'password is required.',
          value => (!!value && value.length > 5) || 'need more than 5 characters',
        ],
        number: [value => !!value || 'number is required.', value => (!!value && value > 0) || 'need more than 0'],
      },
      datas: {
        bank: null,
        bank_name: '',
        bank_account: null,
        username: null,
        password: null,
        use: true,
        use_withdraw: true,
        time_from: null,
        time_to: null,
        limit_withdraw: 0,
        promptpay: null,
        detail: null,
      },
      breadcrumbsItems: [
        {
          text: this.$t('bank.head'),
          disabled: false,
          href: '/bank',
        },
        {
          text: this.$t('bank.edit'),
          disabled: true,
          href: '',
        },
      ],
      withdrawAccountStatus: false,
      bankItems: [],
      statusItems: [
        {
          text: this.$t('open'),
          value: true,
        },
        {
          text: this.$t('close'),
          value: false,
        },
      ],
      id: this.$route.params.id,
    }
  },
  async created() {
    this.addLogPage()
    try {
      this.bankItems = await this.$store.dispatch('getTypeBankData')
      this.data = await this.$store.dispatch('getAccountBank', { id: this.id })
      this.datas = {
        bank: this.data.data[0].bank,
        bank_name: this.data.data[0].bank_name,
        bank_account: this.data.data[0].bank_account,
        username: this.data.data[0].username,
        password: this.data.data[0].password,
        use: this.data.data[0].use ? true : false,
        use_withdraw: this.data.data[0].use_withdraw ? true : false,
        time_from: this.data.data[0].time_from,
        time_to: this.data.data[0].time_to,
        limit_withdraw: this.data.data[0].limit_withdraw,
        promptpay: this.data.data[0].promptpay,
        detail: this.data.data[0].detail,
      }
    } catch (e) {
    }
  },
  methods: {
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await axios.get('https://api.ipify.org?format=json').then(res =>
          data = {
            ip: res.data.ip,
            name: 'ตั้งค่าธนาคาร',
            url: window.location.href,
            detail: 'แก้ไขธนาคาร',
            admin: userSystem.name ? userSystem.name : '' ? userSystem.name : '',
          }
        )
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    async save() {
      this.loading = true
      if (this.$refs.form.validate()) {
        let params = {
          id: this.id,
          username: this.datas.username,
          password: this.datas.password,
          use: this.datas.use ? true : false,
          use_withdraw: this.datas.use_withdraw ? true : false,
          time_from: this.datas.time_from,
          time_to: this.datas.time_to,
          limit_withdraw: parseFloat(this.datas.limit_withdraw),
          promptpay: this.datas.promptpay,
          detail: this.datas.detail,
        }
        try {
          params.limit_withdraw = parseFloat(params.limit_withdraw)
          await this.$store.dispatch('updateAccountBank', params)
          this.$router.push({ name: 'bank' })
        } catch (e) {
          if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
            this.$swal.fire({
              icon: 'error',
              title: e.message,
            })
          }
        }
      } else {
      }
      this.loading = false
    },
  },
}
</script>
